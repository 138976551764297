<template>
  <el-row
    type="flex"
    justify="end"
    class="content__buttons-row"
  >
    <el-col :span="24">
      <slot />
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: 'ContentButtons',
};
</script>

<style lang="scss">
.content {
  &__buttons-row {
    padding-top: 30px;
    text-align: right;
  }
}
</style>
