<template>
  <div class="orders-index width-100">
    <el-table
      :data="orderSamples"
      style="width: 100%"
      border
      @expand-change="loadSampleAnalyses"
    >
      <el-table-column
        :label="$t('samples.create.productName')"
        prop="productName"
      />
      <el-table-column
        type="expand"
        :label="$t('filters.actions')"
        width="100"
      >
        <template slot-scope="props">
          <el-table
            :data="sampleAnalyses[props.row.id]"
            style="width: 100%"
            border
            height="200"
          >
            <el-table-column
              v-if="sampleAnalyses[props.row.id]
                .some(val => typeof val.children !== 'undefined' && val.children.length )"
              type="expand"
              fixed="left"
            >
              <template slot-scope="childProps">
                <div
                  v-for="child in childProps.row.children"
                  :key="child.id"
                >
                  <p class="child-description">
                    {{ child.analysis.description }}
                  </p>
                </div>
              </template>
            </el-table-column>

            <el-table-column
              prop="code"
              :label="$t('filters.code')"
            />
            <el-table-column
              prop="description"
              :label="$t('filters.description')"
            />
          </el-table>
        </template>
      </el-table-column>
    </el-table>
    <div>
      <ContentButtons>
        <router-link
          v-if="orderExists"
          :to="{name: 'editOrder', params: {orderId: order.id}}"
          tag="el-button"
          type="secondary"
        >
          {{ $t('global.edit') }}
        </router-link>
        <el-button
          v-if="order.status === 'draft'"
          type="secondary"
          native-type="submit"
          @click="saveForLater()"
        >
          {{ $t('orders.saveForLater') }}
        </el-button>
        <el-button
          type="primary"
          native-type="submit"
          :loading="loading"
          @click="sendOrder()"
        >
          {{ $t('orders.send') }}
        </el-button>
      </ContentButtons>
    </div>
  </div>
</template>
<!-- eslint-disable no-param-reassign -->
<script>
import storage from 'store';
import { ordersApi, samplesApi } from '@/api';
import ContentButtons from '@/components/ContentButtons.vue';
// import { sampleAnalysesColumns } from '@/config';

export default {
  name: 'OrderSummary',
  components: {
    ContentButtons,
  },
  data() {
    return {
      order: {},
      orderSamples: [],
      sampleAnalyses: {},
      postData: [],
      loading: false,
    };
  },
  computed: {
    orderExists() {
      return typeof this.order.id !== 'undefined';
    },
  },
  created() {
    this.getOrder();
    this.getOrderSamples();
    // this.getOrderSamplesAnalyses();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name === 'ordersIndex') {
        vm.getOrderSamplesAnalyses();
      }
    });
  },
  methods: {
    async getOrder() {
      try {
        const order = await ordersApi.show(this.$route.params.orderId);
        this.order = order.data;
      } catch (error) {
        this.$notify({ showClose: true, type: 'error', message: error.response.data.error.message });
      }
    },
    sendOrder() {
      this.$confirm(this.$t('orders.create.confirmSend'), '', {
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            try {
              this.loading = true;
              instance.confirmButtonLoading = true;
              await this.saveOrderSampleAnalyses();
              if (this.order.status === 'draft') {
                await ordersApi.patch(this.$route.params.orderId, { status: 'sent' });
              }
              this.$notify({ showClose: true, type: 'success', message: this.$t('orders.create.sent', { orderId: this.$route.params.orderId }) });
              this.$router.push({ name: 'ordersIndex' }); // May change later
              instance.confirmButtonLoading = false;
              done();
            } catch (error) {
              instance.confirmButtonLoading = false;
              done();
              this.loading = false;
              this.$notify({ showClose: true, type: 'error', message: error.response.data.error.message });
            }
          } else {
            done();
          }
        },
      });
    },
    async saveOrderSampleAnalyses() {
      try {
        this.postData = [];
        this.orderSamples.forEach(async (sample) => {
          this.loadSampleAnalyses(sample);
          this.postData.push(this.buildSamplePostData(sample));
        });
        await samplesApi.orderSamplesAnalyses(this.order.id, this.postData);
      } catch (error) {
        this.$notify({ showClose: true, type: 'error', message: error.response.data.error.message });
      }
    },
    buildSamplePostData(sample) {
      return {
        sampleId: sample.id,
        analyses: this.sampleAnalyses[sample.id]
          .map((analysis) => {
            const formattedAnalysis = { offerItemId: analysis.id };
            const formattedDynamicAnalysis = analysis.children
              ? analysis.children.map(child => child.analysisId) : [];

            if (formattedDynamicAnalysis.length) {
              formattedAnalysis.dynamicAnalyses = formattedDynamicAnalysis;
            }
            return formattedAnalysis;
          }),
      };
    },
    async getOrderSamples() {
      try {
        const orderSamples = await ordersApi.samplesIndex(this.$route.params.orderId);
        this.orderSamples = orderSamples.data;
      } catch (error) {
        this.$notify({ showClose: true, type: 'error', message: error.response.data.error.message });
      }
    },
    loadSampleAnalyses(sample) {
      try {
        let analyses = [];
        const selectedSampleAnalyses = storage.get('selectedSampleAnalyses');
        if (typeof selectedSampleAnalyses !== 'undefined' && typeof selectedSampleAnalyses[sample.id] !== 'undefined') {
          analyses = Object.keys(selectedSampleAnalyses[sample.id])
            .map(key => selectedSampleAnalyses[sample.id][key]);
        }
        this.sampleAnalyses[sample.id] = analyses;
      } catch (error) {
        this.$notify({ showClose: true, type: 'error', message: error.response.data.error.message });
      }
    },
    async getOrderSamplesAnalyses() {
      const sampleAnalyses = {};
      try {
        const orderSamplesAnalyses = await samplesApi.getOrderSamplesAnalyses(
          this.$route.params.orderId,
        );
        orderSamplesAnalyses.data.forEach((sample) => {
          const { sampleId } = sample;
          if (!sampleAnalyses[sampleId]) {
            sampleAnalyses[sampleId] = new Map();
          }
          sample.offerItems.forEach((item) => {
            sampleAnalyses[sampleId].set(item.id, item);
          });
        });
        const list = {};
        Object.keys(sampleAnalyses).forEach((key) => {
          const obj = {};
          sampleAnalyses[key].forEach((v, k) => { obj[k] = v; });
          list[key] = obj;
        });
        storage.set('selectedSampleAnalyses', list);
      } catch (error) {
        this.$notify({ showClose: true, type: 'error', message: error.response.data.error.message });
      }
    },
    saveForLater(query = {}) {
      this.$confirm(this.$t('orders.create.confirmSend'), '', {
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            try {
              instance.confirmButtonLoading = true;
              await this.saveOrderSampleAnalyses();
              await ordersApi.patchOrder(
                this.order.id,
                { customerOrderNumber: this.order.customerOrderNumber },
              );
              this.$notify({ showClose: true, type: 'success', message: this.$t('orders.create.saved') });
              this.$router.push({ name: 'ordersIndex', query }); // May change later
              instance.confirmButtonLoading = false;
              done();
            } catch (error) {
              instance.confirmButtonLoading = false;
              done();
              this.$notify({ showClose: true, type: 'error', message: error.response.data.error.message });
            }
          } else {
            done();
          }
        },
      });
    },
  },
};
</script>
<style lang="scss">
.child-description {
  margin: 1rem 0 1rem 4rem;
}
</style>
